<template>
  <div class="functionTypes">
    <div>
  <div>
    <a-button type="primary" style="margin-bottom: 10px" @click="()=>this.addShow = true" >新增 </a-button>
  </div>
    <a-table  class="AStockOutDetailTable" :columns="columnsAdd"  :dataSource="dataSource" :pagination="false" > 
      <span slot="score">
         <a-tooltip>
            <template #title>
              <p>图片、PDF、外链等类型为每个0.1分</p>
              <p>日程管理为每场1分</p>
              <p>云展厅管理为每条0.1分</p>
            </template>
              分值  <img src="../../../assets/img2.png" style="width:13px;height:13px;margin-top:-10px"> 
        </a-tooltip> 
        
      </span>
      <span slot="is_Open" slot-scope="text, record" >
        <a-switch
        v-model="record.isOpen!==0"
        checked-children="开"
        un-checked-children="关"
        @click="switchChange(record.isOpen === 1? 0 : 1,record)"
        default-checked
        />
      </span>

      <span slot="is_Home" slot-scope="text, record">
        <a-switch
        v-model="record.isHomeFunc!==0"
        checked-children="开"
        un-checked-children="关"
        @click="switchChange1(record.isHomeFunc === 1? 0 : 1,record)"
        default-checked
        />
      </span> 
      <span slot="is_operation" slot-scope="text,record">
        <a  style="margin-right:5px; font-weight:550"  @click="editBtn(record.id)">修改</a>
        <a-popconfirm
                 title="确定删除?"
                 ok-text="是"
                 cancel-text="否"
                 @confirm="del(record)"
                 @cancel="cancel"> 
         <a   style="font-weight:550">删除</a>
      </a-popconfirm>
       </span>
    </a-table>
    <div  class="page">
      <p>共{{pagination.total}}条记录  第{{pagination.current}}/{{ pagination.total_page }}页</p>  
    <!-- 分页 -->
        <a-pagination
        :current="pagination.current"
        :total="pagination.total" 
        show-quick-jumper
        @change="onchange1"
        show-size-changer 
        @showSizeChange="onShowSizeChange"
         />
    </div>
</div>

  <!-- 新增 -->
  <a-modal v-model="addShow" title="新增"  @ok="addOk"  @cancel="addcancel">
        <a-form-model  :rules="addRules" :model="addForm" hideRequiredMark ref="ruleForm_add" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-model-item label="功能名称" prop="name">
            <a-input v-model="addForm.name" />
          </a-form-model-item>
          <a-form-model-item label="code值" prop="code" >
            <a-input v-model="addForm.code" />
          </a-form-model-item>
          <a-form-model-item label="访问地址" prop="url" >
            <a-input v-model="addForm.url" type="textarea"  />
          </a-form-model-item>
          <a-form-model-item label="分值" prop="score" >
            <a-input v-model="addForm.score"/>
          </a-form-model-item>
          <div style="display: flex;width: 75%;flex-wrap: wrap;padding: 15px;justify-content: space-between;height: 100px">
            <div class="switches">九宫格功能: &nbsp;<a-switch  @change="funcWitch" checked-children="是" un-checked-children="否" default-un-checked /></div>
            <div class="switches">是否开启: &nbsp;<a-switch @change="openWitch"  checked-children="是" un-checked-children="否" default-un-checked /></div>
          </div>
        </a-form-model>

  </a-modal>
  <!-- 修改 -->
  <a-modal v-model="editShow" title="编辑"  @ok="editOk">
        <a-form-model :rules="addRules" :model="editForm"  ref="editItem" hideRequiredMark  :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-model-item label="功能名称" prop="name">
            <a-input v-model="editForm.name" />
          </a-form-model-item>
          <a-form-model-item label="code值" prop="code" >
            <a-input v-model="editForm.code" />
          </a-form-model-item>
          <a-form-model-item label="页面地址" prop="url" >
            <a-input v-model="editForm.url" type="textarea" />
          </a-form-model-item>
          <a-form-model-item label="分数"  prop="score">
            <a-input v-model="editForm.score"  />
          </a-form-model-item>
          <div style="display: flex;width: 75%;flex-wrap: wrap;padding: 15px;justify-content: space-between;height: 100px">
            <div class="switches">九宫格功能: &nbsp;
              <a-switch :checked="editForm.isHomeFunc!==0" @click="funcEdit(editForm.isHomeFunc === 1? 0 : 1)"
                        checked-children="是" un-checked-children="否"  />
            </div>
            <div class="switches">是否开启: &nbsp;
              <a-switch :checked="editForm.isOpen!==0" @click="openEdit(editForm.isOpen === 1? 0 : 1)"
                        checked-children="是" un-checked-children="否"  />
            </div>
          </div>

        </a-form-model>
      </a-modal>
  </div>
</template>

<script>
import {getFunctionTypes,
  delFunction,
  addTypes,
  Edittypes,
  getOneType} from  '../../../service/dictionary'

  export default {
  name: "functionTypes",
  data(){
    return{
      name:'functionTypes',
      dataSource:[],
      addShow:false,
      editShow:false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      addForm:{
        isOpen: 0,
        isHomeFunc:0
      },
      editForm:{},
      pagination:{
      total:0,
      current: 1,
      pageSize:10,
      total_page:0,//总页数
      },
      addRules: {
       name:[{ required: true, message: '请输入名称', trigger: 'blur' }],
       code:[{ required: true, message: '请输入code', trigger: 'blur' }],
       url:[{ required: true, message: '请输入url', trigger: 'blur' }],
       score:[{ required: true, message: '请输入分数', trigger: 'blur' }],
      },
      columnsAdd:[
        {
          title:'功能名称',
          dataIndex:'name'
        },
        {
          title:'Code',
          dataIndex:'code'
        },
        {
          title:'页面地址',
          dataIndex:'url'
        },
        {
          dataIndex:'score',
          // scopedSlots: { customRender: 'score' },
          slots:{title:'score' } 
        },
        {
          title:'是否开启',
          dataIndex:'isOpen',
          scopedSlots: { customRender: 'is_Open' }
        },
        {
          title:'是否九宫格功能',
          dataIndex:'isHomeFunc',
          scopedSlots: { customRender: 'is_Home' }
        },
        {
          title:'创建时间',
          dataIndex:'createdTime',
        },
        {
        title:'操作',
         dataIndex:'operation',
         scopedSlots: { customRender: 'is_operation' }
        }
      ]
     }},

  created() {
    this.$store.dispatch('setManageHeaderTitle',"首页功能类型管理")
  },
   mounted() {
     this.getFunctionType()
   },
 
  methods: {
    // 获取模板类型
     getFunctionType(){
      let data={
        pageNum:this.pagination.current,
        pageSize:this.pagination.pageSize
      }
     getFunctionTypes(data).then((res)=>{
      console.log("222",res);
      this.dataSource=res.data.types
      this.pagination.total=res.data.count
      this.pagination.total_page = this.pagination.total == 0 ? 1 : Math.ceil(this.pagination.total / this.pagination.pageSize);
     })
   },
     //九宫格功能
    funcWitch(checked){
      if (checked === false){
        this.addForm.isHomeFunc = 0
      }else {
        this.addForm.isHomeFunc = 1
      }
    },
    //是否开启
    openWitch(checked){
      if (checked === false){
        this.addForm.isOpen = 0
      }else {
        this.addForm.isOpen = 1
      }
    },
    // 新增确定按钮
    async addOk(){
      this.$refs.ruleForm_add.validate(async valid =>{
        if(valid){
          const addFrom = await addTypes(this.addForm)
          if (addFrom.code === 0){
            this.$message.success("添加成功")
            await this.getFunctionType()
            this.addShow=false
            this.$refs.ruleForm_add.resetFields()
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    // 新增取消
    addcancel(){
      this.addShow=false
      this.$refs.ruleForm_add.resetFields()
     },
    openEdit(open){
       this.editForm.isOpen = open
     },
     funcEdit(open){
      this.editForm.isHomeFunc = open
     },
    async editBtn(id){
      this.editShow=true
      var a=id
       const response= await getOneType(a)
       if(response.code===0){
        this.editForm = response.data
       }
    },
    // 修改
    async editOk(){
      this.$refs.editItem.validate(async valid =>{
        if(valid){
          const id = this.editForm.id
          const edit = await Edittypes(id,this.editForm)
          if (edit.code === 0){
            await this.getFunctionType()
            this.$refs.editItem.resetFields();
            this.$message.success("修改成功")
            this.editShow=false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    async  switchChange(a,record){
          let data={
            name:record.name,
            code:record.code,
            url:record.url,
            isOpen:a,
            isHomeFunc:record.isHomeFunc,
            score:record.score
          }
          // 调用修改函数
          const response=await Edittypes(record.id,data)
            if(response.code===0){
              this.$message.success("修改成功")
              await this.getFunctionType()
            }
            else{
               this.$message.warning("修改失败")
             }
         },
    async  switchChange1(a,record){
          let data={
            name:record.name,
            code:record.code,
            url:record.url,
            isOpen:record.isOpen,
            isHomeFunc:a,
            score:record.score
          }
      // 调用修改函数
        const response=await Edittypes(record.id ,data)
            if(response.code===0){
              this.$message.success("修改成功")
              await this.getFunctionType()
            }
            else{
               this.$message.warning("修改失败")
             }
         },
  //  删除
    async  del(record){
           const response =await delFunction(record.id)
           if(response.code===0){
              this.$message.success('删除成功')
              await this.getFunctionType()
           }
           else {
            this.$message.warning("删除失败")
           }
           },
    cancel(){
      this.$message.warning('取消删除');
       },
     tableChange(e){
         this.pagination.current=e
         this.getFunctionType()
  },
  onchange1(current){
        this.pagination.current=current
        this.getFunctionType()
      },
  // 改变页数时调用
  onShowSizeChange(current,pageSize){
        this.pagination.pageSize=pageSize
        this.current=1
        this.getFunctionType()
        },
}
}
</script>

<style lang="scss" scoped>

.page{
  display: flex;
  font-size: 19px;
   margin-top:20px;
  margin-left: 30px;
  color:#b4a7a7;
}
.page{
 display: flex;
 justify-content:space-between;
 margin-top: 15px;
}
.ADD{
  display: flex;
}
.card{
  position: absolute;
  width:290px;
  height:65px;
  top:69px;
  left:640px;
  border-radius: 6px;
  background: black;
  p{
    font-size: 16px;
    color: white;
  }
}
</style>
